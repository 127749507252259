import React from 'react';
import { List, Row, Col, Button } from 'antd';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

import { Page, Section, styles } from '../layout/page';
import Cloud from '../layout/cloud';
import Headline from '../layout/headline';
import HeadlineSection from '../layout/headline-section';
import FoodForEveryone from '../layout/food-for-everyone';

const color = {
  white: '#fff',
  primary: '#000',
  secondary: '#77818c',
  tertiary: '#3d4752',
  light: '#a0afbf',
  link: '#00bbff',
  highlight: '#ff005e',
};

const tags = [
  { value: 'Blockchain', count: 15, color: color.highlight },
  { value: 'Ethereum', count: 10, color: color.link },
  { value: 'Tokens', count: 8, color: color.secondary },
  { value: 'ERC-20', count: 6, color: color.tertiary },
  { value: 'Shared Ledger', count: 5, color: color.secondary },
  { value: 'DApp', count: 4, color: color.tertiary },
  { value: 'AI', count: 12, color: color.link },
  { value: 'Machine Learning', count: 7, color: color.secondary },
  { value: 'Data Science', count: 7, color: color.primary },
  { value: 'Analytics', count: 12, color: color.highlight },
];

const analytics = [
  'Snapshots of key performance markers',
  'Predictions based on historical trends',
  'Recommendations and suggestions for optimizations',
];

const features = [
  'Keep menus up-to-date with realtime publishing',
  'Increase traffic with Search Engine Optimized (SEO) content',
  'Enable customers to find what they want fast with instant search',
  'Enhance each menu item with photos, details, and profiles',
  'Add value with suggested pairings',
  'Engage customers with favorites and ratings',
  'Receive worry-free feedback from private reviews',
  'Reward subscribers with donation credits',
];

const Home = () => (
  <Page>
    <FoodForEveryone />

    <Section color='blue'>
      <Row gutter={32} type='flex' justify='center'>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Headline
            background='blue'
            lead='What is'
            emphasis='LiveMenu?'
            subtitle='Interactive food and drink menus that help end local hunger.'
          />
          <br />
          <div className={styles.body}>
            <p className={styles.inline}>
              <span className={styles.subtitle}>
                Regain lost opportunities.
              </span>{' '}
              Most online menus for restaurants, bars, breweries, and other food
              and drink establishments are flat &mdash; they only allow
              customers to read them. Get app-like interactivity that enages
              patrons and drastically increases subscribers.
            </p>
            <p>
              <span className={styles.subtitle}>
                Fuel donations to food-based charities.
              </span>{' '}
              Gain and engage customers by rewarding them for specific actions
              with donations on their behalf, directly to food-based charities.
              The more your customers interact, the more they earn rewards, and
              the more you gain valuable insights about those customers.
            </p>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <div className={styles.body}>
            <br />
            <h2>Key Features</h2>
            <List
              bordered={false}
              dataSource={features}
              renderItem={item => (
                <List.Item className={'black'}>
                  <FontAwesomeIcon
                    className='list-item-icon white'
                    icon={faCircle}
                  />
                  {item}
                </List.Item>
              )}
            />
            <Button
              className={styles.action}
              href='/contact'
              type='primary'
              size='large'>
              <span>Request a Demo</span>
            </Button>
          </div>
        </Col>
      </Row>
    </Section>

    <HeadlineSection
      justify='start'
      background='white'
      title={{ lead: 'Rethink', emphasis: 'Your Menus' }}
      subtitle={`Nearly 60% of web traffic is mobile.`}
      button={{ label: 'Engage Customers', url: '/contact' }}>
      <p>
        Guests use phones to find food and drink menus. Evolve your online
        presence with interactive, mobile-optimized menus that increase site
        traffic, engage digital-savvy customers, and enable a closer connection
        between you, your food, and your patrons.
      </p>
    </HeadlineSection>

    <HeadlineSection
      justify='center'
      background='black'
      title={{ lead: 'Reach the', emphasis: 'Right Guests' }}
      subtitle={`Notify the customers that care.`}
      button={{ label: 'Connect Now', url: '/contact' }}>
      <p>
        You have 10 seconds to catch their attention. It's the difference
        between DELETE and walking into your establishment tonight. Connect with
        and notify the patrons most likely to respond with effective,
        behavior-driven messages.
      </p>
    </HeadlineSection>

    <HeadlineSection
      justify='end'
      background='blue'
      title={{ lead: 'Make Better', emphasis: 'Decisions' }}
      subtitle={`Some of us are good guessers. Most of us aren't.`}
      button={{ label: 'Get Informed', url: '/contact' }}>
      <p>
        Make informed, accurate decisions for your food establishment with
        data-driven analysis that helps you improve operations and grow revenue
        and customer base.
      </p>
      <List
        bordered={false}
        dataSource={analytics}
        renderItem={item => (
          <List.Item className={'black'}>
            <FontAwesomeIcon className='list-item-icon white' icon={faCircle} />
            {item}
          </List.Item>
        )}
      />
    </HeadlineSection>
  </Page>
);

export default Home;
